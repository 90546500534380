const tenantCustonConfig = {
  brandName: "Wapa",
  dropdownMenuItems: ['bankAccount', 'config', 'buyReader', 'upgradeToProfessionalLink'],
  automaticCashout: {
    isEnabled: false,
  },
  termsAndConditions: false,
  stats: {
    statsEnabled: ["products", "staff"],
  },
  sales: {
    transactionReceipt: {
      qr: [
        {
          label: "Titular de tarjeta",
          method: (sale) => sale.payment.cardHolderName,
        },
        {
          label: "Tasa comisión",
          method: (sale) => sale.commission + "%",
        },
        {
          label: "Fecha de disponibilidad",
          method: (sale) => sale.availableDate,
        },
        {
          label: "N° de referencia",
          method: (sale) => sale.referenceNumber,
        },
        {
          label: "Nº de autorización",
          method: (sale) => sale.authorizationCode,
        },
      ],
    }
  },
  currencyDecimals:true,
  tenantMaxLength: {
    phoneLength: 12,
    documentLength: 9,
  },
  createOrChangePasswordMinLengthValue: 12,
  createOrChangePasswordReqsField: (minLengthValue) => {
    return [
      {
        test: (value) => value && value.length >= minLengthValue,
        label: "resetPassword.form.userPassword.requirements.minLength",
      },
      {
        test: (value) => value && value.match(/[a-z]/),
        label: "resetPassword.form.userPassword.requirements.lowcase",
      },
      {
        test: (value) => value && value.match(/[A-Z]/),
        label: "resetPassword.form.userPassword.requirements.upcase",
      },
      {
        test: (value) =>
          value && value.match(/[!@#$%^&*()_+|~-\s=\\`{}\[\]:";'<>?,./]/), //eslint-disable-line
        label: "resetPassword.form.userPassword.requirements.specialChar",
      },
      {
        test: (value) => value && value.match(/\d/i),
        label: "resetPassword.form.userPassword.requirements.anyNumber",
      },
    ]
  },
  createOrChangePasswordReqsSubmit: [
    {
      field: "password",
      test: (value) =>
        value && value.length >= 12 && value.match(/[a-z]/) && value.match(/[A-Z]/) && value.match(/[^a-zA-Z0-9]/) && value.match(/\d/i),
    },
  ]
};

const tenantTableColumns = {};

export {
  tenantCustonConfig,
  tenantTableColumns,
}
